import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { NextUIProvider } from "@nextui-org/react";
import MobileApp from "./MobileApp"; // Renamed your existing App for mobile
import DesktopApp from "./DesktopApp"; // New desktop-specific component
import './styles.css';
import { isMobile } from "react-device-detect";

// Function to determine if the device is in portrait mode
const isPortraitMode = () => window.innerWidth < window.innerHeight;

function Main() {
    const [orientation, setOrientation] = useState(isPortraitMode());

    // Hook to listen for changes in orientation and update state
    useEffect(() => {
        const handleOrientationChange = () => {
            setOrientation(isPortraitMode());
        };

        window.addEventListener("resize", handleOrientationChange);

        return () => {
            window.removeEventListener("resize", handleOrientationChange);
        };
    }, []);

    // Render message for mobile users in landscape mode
    if (isMobile && !orientation) {
        return (
            <div className="dark w-screen h-screen flex flex-col items-center justify-center bg-black text-white">
                <p className="text-center text-2xl font-bold">
                    Пожалуйста, переверните устройство в портретный режим для более удобного просмотра.
                </p>
            </div>
        );
    }

    // Render mobile app if it's a mobile device and in portrait mode
    if (isMobile && orientation) {
        return (
            <React.StrictMode>
                <NextUIProvider>
                    <MobileApp />
                </NextUIProvider>
            </React.StrictMode>
        );
    }

    // Render desktop app for non-mobile devices
    return (
        <React.StrictMode>
            <NextUIProvider>
                <DesktopApp />
            </NextUIProvider>
        </React.StrictMode>
    );
}

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.createRoot(rootElement).render(<Main />);
} else {
  console.error("No root element found in the DOM.");
}
