"use client";

import React, { useState, useEffect, useRef, useCallback } from "react";
import { cn, image } from "@nextui-org/react";
import PaintingListItem from "./mobile-painting-list-item";
import Spinner from "./mobile-spinner";
import axios from "axios";
import { Icon } from "@iconify/react";

const youtubeUrl = "https://youtu.be/tHY3NkSewy8";
const telegramUrl = "https://t.me/+79119690469";
const apiBaseUrl = "https://art.paintx.ru/paintings";
const whatsappUrl = "https://wa.me/79119690469";
const yandexMapsUrl = "https://yandex.ru/maps/org/paintx/49452764850";

export default function Component({ className }) {
    const [paintings, setPaintings] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const observer = useRef();
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [scrollDirection, setScrollDirection] = useState("up"); // New state for scroll direction
    const prevScrollY = useRef(0); // To track the previous scroll position
    const [searchQuery, setSearchQuery] = useState(""); // Search query state
    const [hasMore, setHasMore] = useState(true);
    const debounceTimeout = useRef(null); // Reference for debounce timer
    const debounceScroll = useRef(null); // Reference for scroll debounce timer
    const isFetching = useRef(false);
    const [imageLoading, setImageLoading] = useState({});

    const paintingsPerPage = 5;
    let splash = true;

    const handleSearchToggle = () => {
        setIsSearchVisible((prev) => !prev);
    
        if (isSearchVisible) {
        // Clear search results and reset to default unsearched results
            setSearchQuery("");
            setPage(1);
            setPaintings([]);
        }
    };

    // Update search query and reset pagination
    const handleSearch = (query) => {
        if (!query) {
            // Reset to default unsearched results if the query is cleared
            setSearchQuery("");
            setPage(1);
            setPaintings([]);
        } else {
            setSearchQuery(query);
            setPage(1);
            setPaintings([]);
        }
    };

    // Debounced input change handler
    const handleInputChange = (event) => {
        const query = event.target.value;
        // Clear the previous timeout
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
        // Set a new timeout
        debounceTimeout.current = setTimeout(() => {
            handleSearch(query); // Only triggers when typing has paused for the debounce duration
        }, 1000); // 300ms delay
    };

    // Handle scroll direction detection
    // const handleScroll = useCallback(() => {
    //    if (debounceScroll.current) clearTimeout(debounceScroll.current);
    //    debounceScroll.current = setTimeout(() => {
    //        const currentScrollY = window.scrollY;
    //        if (currentScrollY > prevScrollY.current && currentScrollY > 50) {
    //            // Scrolling down
    //            setScrollDirection("down");
    //        } else if (currentScrollY < prevScrollY.current) {
    //            // Scrolling up
    //            setScrollDirection("up");
    //        }
    //        prevScrollY.current = currentScrollY;
    //    }, 200); // Debounce delay
    //}, []);

    // Handle scroll direction detection without debounce
    const handleScroll = useCallback(() => {
        const currentScrollY = window.scrollY;
        if (currentScrollY > prevScrollY.current && currentScrollY > 50) {
            // Scrolling down
            setScrollDirection("down");
        } else if (currentScrollY < prevScrollY.current) {
            // Scrolling up
            setScrollDirection("up");
        }
        prevScrollY.current = currentScrollY;
    }, []);

    useEffect(() => {
        // Attach the scroll listener
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);

    useEffect(() => {
        if (!hasMore || isFetching.current) return; // Prevent overlapping requests and unnecessary fetches

        // function to fetch paintings from the API
        const fetchPaintings = async (retryCount = 3) => {
            if (page === 1 && paintings.length > 0) {
                return; // Avoid re-fetching the same data if it already exists
            }

            let timeout;
            try {
                isFetching.current = true;
                setIsLoading(true);

                const source = axios.CancelToken.source();
                timeout = setTimeout(() => {
                    source.cancel("Timeout");
                }, 10000);

                const response = await axios.get(
                    `${apiBaseUrl}?page=${page}&limit=${paintingsPerPage}${searchQuery ? `&search=${searchQuery}` : ""}`,
                    { cancelToken: source.token }
                );

                clearTimeout(timeout);
                console.log("Fetched data: ", response.data);
                
                const initialLoadingState = response.data;
                response.data?.forEach((painting) => {
                    initialLoadingState[painting.id] = true;
                });

                setImageLoading(prev => ({ ...prev, ...initialLoadingState }));

                setPaintings((prevPaintings) => {
                    const newPaintings = response.data.filter(
                        (newPainting) =>
                        !prevPaintings.some((prevPainting) => prevPainting.id === newPainting.id)
                    );
                    console.log("Filtered New Paintings: ", newPaintings); // Log filtered data
                    return [...prevPaintings, ...newPaintings];
                });

                // Only set hasMore to false if fewer than paintingsPerPage items are returned
                if (response.data.length < paintingsPerPage) {
                    setHasMore(false);
                    console.log("No more paintings to fetch");
                }

            } catch (error) {
                clearTimeout(timeout);
                if (axios.isCancel(error)) {
                    console.log("Request canceled:", error.message);
                } else if (retryCount > 0) {
                    console.log("Timeout, retrying... attempts left:", retryCount);
                    fetchPaintings(retryCount - 1);
                } else {
                    console.error("Error fetching paintings:", error);
                    setIsLoading(false);
                }
            } finally {
                isFetching.current = false;
                setIsLoading(false);
            }
        };

        fetchPaintings();

    }, [page, searchQuery, hasMore]);

    // Callback for last element to be observed for infinite scroll
    const lastPaintingRef = useCallback(
        (node) => {
            if (isLoading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading]
    );

    const getTelegramMessage = () => {
        const isSingleColumn = window.matchMedia("(max-width: 640px)").matches;
        let message = "Здравствуйте!";

        if (!isSingleColumn) {
            console.log("Not in single column mode");
            return encodeURIComponent(message);
        }

        console.log("In single column mode:", isSingleColumn);

        const paintingElements = document.querySelectorAll("[data-id]");
        let mostVisibleElement = null;
        let maxVisibleArea = 0;

        paintingElements.forEach((element) => {
            const rect = element.getBoundingClientRect();
            if (rect.bottom > 0 && rect.top < window.innerHeight) {
                const visibleHeight = Math.min(rect.bottom, window.innerHeight) - Math.max(rect.top, 0);
                const visibleWidth = Math.min(rect.right, window.innerWidth) - Math.max(rect.left, 0);
                const visibleArea = Math.max(0, visibleHeight) * Math.max(0, visibleWidth);

                if (visibleArea > maxVisibleArea) {
                    maxVisibleArea = visibleArea;
                    mostVisibleElement = element;
                }
            }
        });

        if (mostVisibleElement) {
            const imageSrc = mostVisibleElement.getAttribute("data-src");
            console.log("Most visible painting imageSrc: ", imageSrc);
            message = `Здравствуйте! Могли бы вы, пожалуйста, рассказать больше об этой картине? \n\n ${imageSrc}`;
        }

        return encodeURIComponent(message);
    };

    
  return (
    <div className="relative">
      <div
        className={cn(
          "my-auto grid max-w-7xl grid-cols-1 gap-5 p-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5",
          className
        )}
      >
        {paintings.map((painting, index) => {
            // const isLastItem = index === paintings.length - 1;
          if (paintings.length - index === 2) {
            return (
              <PaintingListItem
                key={painting.id}
                data-id={painting.id}
                data-src={painting.imageMidSrc}
                ref={lastPaintingRef}
                isLoading={imageLoading[painting.id] ?? true}
                setImageLoading={setImageLoading}
                {...painting}
              />
            );
          } else {
            return <PaintingListItem 
                    key={painting.id} 
                    data-id={painting.id} 
                    data-src={painting.imageMidSrc} 
                    isLoading={imageLoading[painting.id]} 
                    setImageLoading={setImageLoading} 
                    {...painting} 
                />;
          }
        })}
      </div>

       {/* Render Spinner when loading more items */}
        {isLoading && paintings.length > 0 && <Spinner />}


      {/* Search Icon Button */}
      <div
        className={cn(
          "fixed top-4 left-1/2 transform -translate-x-1/2 z-50 transition-transform duration-500",
          scrollDirection === "down" ? "-translate-y-16" : "translate-y-0"
        )}
      >
        {!isSearchVisible && (
          <button
            onClick={handleSearchToggle}
            className="bg-gray-800/60 text-white p-3 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 backdrop-blur-lg"
          >
            <Icon icon="mdi:magnify" width="28" height="28" />
          </button>
        )}
      </div>

      {/* Search Box - Visible when isSearchVisible is true */}
      {isSearchVisible && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 w-full max-w-md px-4">
          <div className="flex items-center backdrop-blur-lg bg-gray-800/60 shadow-md rounded-full p-3 transition-all duration-300">
            <input
              type="text"
              placeholder="Поиск картин..."
              onChange={handleInputChange}
              className="flex-grow bg-transparent text-white outline-none px-2 placeholder-gray-400"
            />
            <button onClick={handleSearchToggle} className="ml-2 text-gray-400 hover:text-white transition-colors">
              <Icon icon="mdi:close" width="24" height="24" />
            </button>
          </div>
        </div>
      )}

      {/* Contact Buttons */}
      <div
        className={cn(
          "fixed bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4 z-50 backdrop-blur-lg bg-gray-800/60 p-3 rounded-2xl shadow-2xl transition-transform duration-500",
          scrollDirection === "down" ? "translate-y-16" : "translate-y-0"
        )}
        id="contactsPane"
      >
        <button
          onClick={() => {
            window.open(`${telegramUrl}?text=${getTelegramMessage()}`, "_blank");
          }}
          className="bg-blue-500 text-white p-1.5 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-blue-400"
        >
          <Icon icon="simple-icons:telegram" width="24" height="24" />
        </button>
        <button
          onClick={() => window.open(`${youtubeUrl}`, "_blank")}
          className="bg-red-500 text-white p-1.5 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-red-400"
        >
          <Icon icon="simple-icons:youtube" width="24" height="24" />
        </button>
        <button
          onClick={() => {
            window.open(`${whatsappUrl}?text=${getTelegramMessage()}`, "_blank");
          }}
          className="bg-green-500 text-white p-1.5 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-green-400"
        >
          <Icon icon="simple-icons:whatsapp" width="24" height="24" />
        </button>

        <button
          onClick={() => window.open(`${yandexMapsUrl}`, "_blank")}
          className="bg-yellow-500 text-white p-1.5 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-yellow-400"
        >
          <Icon icon="mdi:map-marker" width="24" height="24" />
        </button>
      </div>
    </div>
  );
}
