import React, { useState, useRef, useEffect } from "react";
import { cn } from "@nextui-org/react";
import { Button, Skeleton } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom"; // Use the router hook to navigate


const PaintingListItem = React.forwardRef(
  (
    {
      id,
      title,
      price,
      artist_name,
      availability,
      isLoading,
      description,
      imageSrc,
      imageMidSrc,
      canvas,
      medium,
      style,
      year,
      width,
      height,
      className,
      ...props
    },
    ref
  ) => {
    const [isLiked, setIsLiked] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate(); // Get the navigate function from React Router


    const handleClick = () => {
      setIsModalOpen(true);
    };

    const handleClickNavigateToDetails = () => {
        navigate(`/art/${id}`); // Navigate to the detailed painting page using the painting's ID
    };

    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex flex-col gap-3 p-4 bg-gray-900 bg-opacity-20 rounded-md transition-all ease-in-out",
          className
        )}
        {...props}
        style={{ borderRadius: "10px", transition: "all 0.3s ease" }}
      >
        {/* Like Button */}
        <Button
          isIconOnly
          className="absolute right-7 top-7 z-20 bg-gray-900/50 backdrop-blur-sm backdrop-saturate-150 dark:bg-default-100/50"
          radius="full"
          size="sm"
          variant="flat"
          onPress={() => setIsLiked(!isLiked)}
        >
          <Icon
            className={cn("text-gray-300", {
              "text-danger-400": isLiked,
            })}
            icon="solar:heart-bold"
            width={16}
          />
        </Button>

        {/* Image Container */}
          <img
            alt={title}
            className="w-full h-full object-cover aspect-square rounded-lg"
            src={imageMidSrc}
            onClick={handleClick}
            onDoubleClick={() => setIsLiked(!isLiked)}
            onContextMenu={(e) => {
              e.preventDefault();
            }}
          />

        {/* Price Label */}
        {price > 0 && (
          <div className="absolute left-7 top-7 z-20 bg-gray-800/60 px-2 py-1 rounded-md">
            <span className="text-white">
              {new Intl.NumberFormat("ru-RU", {
                style: "currency",
                currency: "RUB",
                minimumFractionDigits: 0,
              }).format(price)}
            </span>
          </div>
        )}

        {/* Modal to show full painting */}
        {isModalOpen && (
          <div
            className="fixed inset-0 z-[9999] flex items-center justify-center backdrop-blur-lg bg-black/70"
            onClick={handleCloseModal}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="relative max-w-full max-h-full p-4">
              <button
                onClick={handleCloseModal}
                className="absolute top-7 right-7 text-white p-2 rounded-full hover:bg-gray-700 bg-background/60 transition-colors"
              >
                <Icon icon="mdi:close" width={24} height={24} />
              </button>
              <img
                src={imageSrc}
                alt={title}
                className="object-contain max-w-screen max-h-screen rounded-lg shadow-lg"
                onClick={handleCloseModal}
              />
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2 px-1">
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              {/* Title and Artist Name */}
              <div className="flex items-start justify-between gap-1">
                <h3 className="text-small font-medium text-default-700">
                  {title}
                  {year > 0 ? `, ${year}` : null}
                </h3>
                {artist_name !== "Неизвестный Художник" ? (
                  <p className="text-small font-medium text-default-700">
                    {artist_name}
                  </p>
                ) : null}
              </div>

              {/* Modern Chips */}
              <div className="flex items-start justify-start gap-2 mt-2">
                {style !== 'Неизвестный стиль' && (
                  <span className="inline-flex items-center px-3 py-1 bg-gray-800 text-white rounded-full text-xs font-medium">
                    {style}
                  </span>
                )}
                {medium !== 'Неизвестно' && (
                  <span className="inline-flex items-center px-3 py-1 bg-gray-800 text-white rounded-full text-xs font-medium">
                    {medium}
                  </span>
                )}
                {canvas !== 'Неизвестно' && (
                  <span className="inline-flex items-center px-3 py-1 bg-gray-800 text-white rounded-full text-xs font-medium">
                    {canvas}
                  </span>
                )}
                
                <span className="inline-flex items-center px-3 py-1 bg-gray-800 bg-opacity-0 text-white rounded-full text-xs font-medium">
                    &nbsp;
                </span>
              </div>

              {/* Description that can be expanded or collapsed */}
              {description ? (
                <div>
                  <p
                    className={cn(
                      "text-small text-default-500 transition-all duration-300",
                      {
                        "line-clamp-2": !isExpanded,
                      }
                    )}
                    style={
                      isExpanded ? { maxHeight: "none" } : { maxHeight: "4.5em" }
                    } // Proper style usage
                  >
                    {description}
                  </p>

                  {/* Expand/Collapse Button */}
                  {isExpanded && null}
                  <Button
                    className="mt-2 text-sm text-white bg-gray-800 hover:bg-gray-700"
                    size="sm"
                    variant="flat"
                    //onPress={() => setIsExpanded(!isExpanded)}
                    onClick={handleClickNavigateToDetails}
                  >
                    {/*<span>{isExpanded ? "Скрыть" : "Подробности"}</span>*/}
                    <span>Подробнее</span>
                    <Icon
                      icon="mdi:eye-outline"
                      width={16}
                    />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  }
);

PaintingListItem.displayName = "PaintingListItem";

export default PaintingListItem;
