import React from "react";

export default function Spinner() {
  return (
    <div className="flex justify-center items-center py-0 my-8">
      <div className="w-8 h-8 border-4 border-white-500 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
}


//<div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10 h-36">
//                                <div className="w-8 h-8 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
//                            </div>