import React, { useState, useEffect } from "react";

const PaintingPriceRange = ({ minPrice, maxPrice, paintingCount, currentPaintingPrice }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const percentage = ((currentPaintingPrice - minPrice) * 0.95 / (maxPrice - minPrice)) * 100;

    // Toggle Tooltip
    const handleSliderClick = () => setShowTooltip(!showTooltip);

    // Automatically close tooltip after 6 seconds
    useEffect(() => {
        let timeout;
        if (showTooltip) {
            timeout = setTimeout(() => setShowTooltip(false), 4000);
        }
        return () => clearTimeout(timeout);
      }, [showTooltip]);


  return (
    <div className="relative w-full max-w-md flex justify-left items-center space-x-2">
      {/* Price Range Chip with Fixed Width Gradient Bar */}
      <div className="flex items-center bg-gray-700 text-white rounded-full text-sm font-medium py-1 px-4 shadow-md">
        {/* Min Price Label */}
        <span className="mr-2">{minPrice.toLocaleString()}</span>
        
        {/* Gradient Bar with Fixed Width and Current Price Dot */}
        <div className="relative h-4 bg-gradient-to-r from-blue-400 to-red-400 rounded-full cursor-pointer" style={{ width: "100px" }} onClick={handleSliderClick}>
          {/* Current Price Dot */}
          <div
            className="absolute h-3.5 w-3.5 bg-gray-800 rounded-full border border-white cursor-pointer"
            style={{ left: `${percentage}%`, top: '50%', transform: 'translate(-50%, -50%)' }}
            onClick={handleSliderClick}
          ></div>
        </div>

        {/* Max Price Label */}
        <span className="ml-2">{maxPrice.toLocaleString()}</span>
      </div>

      {/* Tooltip Balloon with More Info */}
      {showTooltip && (
        <div
            className="absolute bg-gray-800 text-gray-100 p-4 rounded-lg shadow-lg w-64 z-10 cursor-pointer"
            style={{ top: '110%', left: '50%', transform: 'translateX(-50%)' }}
            onClick={handleSliderClick}
        >
          <h2 className="text-sm font-semibold text-gray-300 mb-2">Диапазон цен картин автора</h2>
          <div className="flex items-center justify-between text-xs mb-1">
            <span className="text-gray-400">Минимальная цена</span>
            <span className="text-gray-300">{minPrice.toLocaleString()} ₽</span>
          </div>
          <div className="flex items-center justify-between text-xs mb-1">
            <span className="text-gray-400">Максимальная цена</span>
            <span className="text-gray-300">{maxPrice.toLocaleString()} ₽</span>
          </div>
          <div className="flex items-center justify-between text-xs mt-2">
            <span className="text-gray-400">Текущая цена этой картины</span>
            <span className="text-gray-300">{currentPaintingPrice.toLocaleString()} ₽</span>
          </div>
          <div className="flex items-center justify-between text-xs mt-2">
            <span className="text-gray-400">Количество картин автора</span>
            <span className="text-gray-300">{paintingCount}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaintingPriceRange;
