// PaintingScroller.js
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Icon } from '@iconify/react';

const PaintingScroller = ({ title, fetchUrl, idPrefix, paintingId }) => {
  const containerRef = useRef(null);
  const [paintings, setPaintings] = useState([]);
  const [scrollState, setScrollState] = useState({
    canScrollLeft: false,
    canScrollRight: false,
  });
  const [isLoading, setIsLoading] = useState(true);
  const hasFetched = useRef(false);

  // Deduplicate paintings by id
  const dedupePaintings = (newPaintings) => {
    const existingPaintingIds = new Set(paintings.map((p) => p.id));
    return newPaintings.filter((newPainting) => !existingPaintingIds.has(newPainting.id));
  };

  // Retry API Call with constant retries
  const fetchWithRetries = async (url, retries = 3, delay = 500) => {
    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      if (retries === 0) {
        throw error; // No retries left, rethrow the error
      }
      console.warn(`Retrying API call (${3 - retries} retries left)...`);
      await new Promise((resolve) => setTimeout(resolve, delay)); // Retry quickly after a short delay
      return fetchWithRetries(url, retries - 1, delay); // Retry without increasing delay
    }
  };

  // Fetching paintings
  const fetchPaintings = async () => {
    try {
      setIsLoading(true);
      const data = await fetchWithRetries(`${fetchUrl}?paintingId=${paintingId}`);
      setPaintings(dedupePaintings(data));
    } catch (err) {
      console.error("Error fetching related paintings after retries", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      hasFetched.current = true;
      fetchPaintings();
    }
  }, [paintingId]);

  // Manage scroll state for buttons
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const updateScrollState = () => {
        setScrollState({
          canScrollLeft: container.scrollLeft > 0,
          canScrollRight: container.scrollWidth > container.clientWidth + container.scrollLeft,
        });
      };

      container.addEventListener("scroll", updateScrollState);
      updateScrollState();

      return () => container.removeEventListener("scroll", updateScrollState);
    }
  }, [paintings]);

  const scrollLeft = () => containerRef.current.scrollBy({ left: -200, behavior: "smooth" });
  const scrollRight = () => containerRef.current.scrollBy({ left: 200, behavior: "smooth" });

  return (
    <div className="relative w-full mt-8"> {/* Added margin at the top */}
      {/* Inscription Title */}
      <h2 className="text-md font-medium text-gray-400 mb-4">{title}</h2> {/* Match right-side text style */}

      <div className="relative">
        {/* Spinner: Show while loading */}
        {isLoading && (
          <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10 h-36">
            <div className="w-8 h-8 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
        )}

        {/* Scroller Content */}
        <div
          ref={containerRef}
          className={`flex overflow-x-auto gap-4 snap-x snap-mandatory scrollbar-hide relative ${
            isLoading ? "h-36" : ""
          }`} // Apply a fixed height while loading
          style={{ minHeight: '144px', maxWidth: '100%' }} // Ensures scroller fits within container
        >
          {!isLoading &&
            paintings.map((painting) => (
              <Link
                key={`${idPrefix}-${painting.id}`}
                to={`/art/${painting.id}`}
                className="snap-center flex-none w-36 h-36 overflow-hidden rounded-lg"
              >
                <img
                  src={painting.image_thumbnail_url}
                  className="object-cover w-full h-full"
                  onContextMenu={(e) => e.preventDefault()}
                />
              </Link>
            ))}
        </div>

        {/* Scroll Buttons */}
        {scrollState.canScrollLeft && (
          <button
            onClick={scrollLeft}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
          >
            <Icon icon="mdi:chevron-left" className="h-6 w-6" /> {/* Left chevron icon */}
          </button>
        )}
        {scrollState.canScrollRight && (
          <button
            onClick={scrollRight}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
          >
            <Icon icon="mdi:chevron-right" className="h-6 w-6" /> {/* Right chevron icon */}
          </button>
        )}
      </div>
    </div>
  );
};

export default PaintingScroller;
