import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function CanonicalUrl() {
    const location = useLocation();

    useEffect(() => {
        // Get the full URL with the current pathname
        const canonicalUrl = `https://paintx.ru${location.pathname}`;

        // Check if a canonical tag already exists in the document
        let link = document.querySelector("link[rel='canonical']");
        if (!link) {
            link = document.createElement("link");
            link.setAttribute("rel", "canonical");
            document.head.appendChild(link);
        }

        // Update the canonical link's href attribute
        link.setAttribute("href", canonicalUrl);
    }, [location]);

    return null; // This component does not render anything directly
}

export default CanonicalUrl;
