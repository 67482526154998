import React, { useState, useRef, useEffect } from "react";
import { cn } from "@nextui-org/react";
import { Button, Image, Skeleton } from "@nextui-org/react";
import { Icon } from "@iconify/react";

const PaintingListItem = React.forwardRef(
  (
    {
      id,
      title,
      price,
      artist_name,
      availability,
      isLoading,
      setImageLoading,
      description,
      imageSrc,
      imageMidSrc,
      canvas,
      medium,
      style,
      year,
      width,
      height,
      removeWrapper,
      className,
      isMobile,
      ...props
    },
    ref
  ) => {
    const [isLiked, setIsLiked] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const lastTapRef = useRef(0);
    const touchStartPosition = useRef({ x: 0, y: 0 });
    const isScrolling = useRef(false);
    const longPressTimeoutRef = useRef(null);

    let lastTouchTime = null;
    let touchTimeout = null;

    useEffect(() => {
      const preventContextMenu = (e) => {
        e.preventDefault();
      };

      // Prevent default context menu and long-press events
      document.addEventListener("contextmenu", preventContextMenu);
      return () => {
        document.removeEventListener("contextmenu", preventContextMenu);
      };
    }, []);

    // Function to handle double tap (mobile) and double click (desktop) for liking
    const handleDoubleTapOrDoubleClick = () => {
        console.log("setIsLiked");
      setIsLiked((prev) => !prev);
    };

    // Function to handle movement to detect scrolling
    const handleTouchMove = (e) => {
      const currentX = e.touches[0].clientX;
      const currentY = e.touches[0].clientY;
      const deltaX = Math.abs(currentX - touchStartPosition.current.x);
      const deltaY = Math.abs(currentY - touchStartPosition.current.y);

      // If the user moved their finger more than a few pixels, we consider it scrolling
      if (deltaX > 10 || deltaY > 10) {
        isScrolling.current = true;
        lastTouchTime = null; // Cancel the long press if scrolling
        clearTimeout(touchTimeout); // Cancel the long press if scrolling
      }
    };

    const handleTouchStart = (e) => {
        isScrolling.current = false;
        // make sure the start position is set on touch start
        touchStartPosition.current = { 
            x: e.touches[0].clientX, 
            y: e.touches[0].clientY 
        };

        // If the user is scrolling, we don't want to trigger the double tap

    };

    const handleTouchEnd = () => {
        if (isScrolling.current) {
            return;
          }

        //isScrolling.current = false;
        const currentTime = Date.now();

        if (lastTouchTime === null) {
          lastTouchTime = currentTime;
          touchTimeout = setTimeout(() => { setIsModalOpen(true);  lastTouchTime = null; }, 300);
        } else {
            console.log("Double tap");
            const delta = currentTime - lastTouchTime;
            console.log("Delta: ", delta);
            if (delta < 300) {
                clearTimeout(touchTimeout);
                handleDoubleTapOrDoubleClick();
                lastTouchTime = null;
            }
        }
    };
      

    // Function to handle single click (for desktop) to open modal
    const handleClick = () => {
      setIsModalOpen(true); // Open the modal on click
    };

    // Function to close the modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    const handleImageLoad = () => {
        setImageLoading((prev) => ({ ...prev, [id]: false}));
    };

    return (
      <div
        ref={ref}
        className={cn(
          "relative flex w-full flex-none flex-col gap-3",
          {
            "rounded-none bg-background shadow-none": removeWrapper,
          },
          className
        )}
        {...props}
        style={{
          userSelect: "none", // Prevent selection of text/images
          WebkitUserSelect: "none",
          WebkitTouchCallout: "none", // Disable callout on long-press in iOS
        }}
      >
        <Button
          isIconOnly
          className="absolute right-3 top-3 z-20 bg-background/60 backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50"
          radius="full"
          size="sm"
          variant="flat"
          onPress={() => setIsLiked(!isLiked)}
          style={{
            userSelect: "none", // Prevent text selection on button
            WebkitUserSelect: "none",
          }}
        >
          <Icon
            className={cn("text-default-900/50", {
              "text-danger-400": isLiked,
            })}
            icon="solar:heart-bold"
            width={16}
          />
        </Button>

        {/* Image - Handle click and double click to open modal or like, respectively */}
        <Image
          isBlurred
          isZoomed
          alt={title}
          className="aspect-square w-full hover:scale-100 w-[100%]"
          //isLoading={isLoading}
          src={imageMidSrc}
          loading="lazy"
          onLoad={handleImageLoad}
          onTouchMove={handleTouchMove} // Handle movement to detect scrolling
          onTouchEnd={handleTouchEnd} // Handle touch start for mobile
          onTouchStart={handleTouchStart} // Handle touch end for mobile
          onClick={window.innerWidth <= 640  ? null : handleClick} // Handle click for desktop
          onContextMenu={(e) => e.preventDefault()} // Prevent right-click context menu
          style={{
            userSelect: "none", // Prevent selection on images
            WebkitUserSelect: "none",
            WebkitTouchCallout: "none", // Disable callout on long-press in iOS
            pointerEvents: "auto", // Ensure image can be interacted with
          }}
        />

        {/* Modal to show full painting */}
        {isModalOpen && (
          <div
            className="fixed inset-0 z-[9999] flex items-center justify-center backdrop-blur-lg bg-black/70"
            onClick={handleCloseModal}
            style={{
              userSelect: "none", // Prevent selection inside modal
              WebkitUserSelect: "none",
              WebkitTouchCallout: "none",
            }}
          >
            <div
              className="relative max-w-full max-h-full p-4"
              onClick={(e) => e.stopPropagation()} // Prevent close when clicking on the image container
              style={{
                userSelect: "none", // Prevent selection inside modal container
                WebkitUserSelect: "none",
                WebkitTouchCallout: "none",
              }}
            >
              <button
                onClick={handleCloseModal}
                className="absolute top-4 right-4 text-white p-2 rounded-full hover:bg-gray-700/50 transition-colors"
                style={{
                  userSelect: "none", // Prevent selection on close button
                  WebkitUserSelect: "none",
                }}
              >
                <Icon icon="mdi:close" width={24} height={24} />
              </button>
              <img
                src={imageSrc}
                alt={title}
                className="object-contain max-w-screen max-h-screen rounded-lg shadow-lg"
                onClick={handleCloseModal} // Close modal by single tapping on the image
                style={{
                  userSelect: "none", // Prevent selection on modal image
                  WebkitUserSelect: "none",
                  WebkitTouchCallout: "none",
                  pointerEvents: "auto",
                }}
              />
            </div>
          </div>
        )}

        <div
          className="mt-1 flex flex-col gap-2 px-1"
          style={{
            userSelect: "none", // Prevent selection on text content
            WebkitUserSelect: "none",
            WebkitTouchCallout: "none",
          }}
        >
          {isLoading ? (
             <div className="skeleton-container">
             <Skeleton className="w-full aspect-square rounded-lg" />
             <Skeleton className="mt-3 w-4/5 h-5 rounded-lg" />
             <Skeleton className="mt-2 w-3/5 h-5 rounded-lg" />
           </div>
          ) : (
            <>
              <div className="flex items-start justify-between gap-1">
                <h3 className="text-small font-medium text-default-700">
                  {title}
                </h3>
                {artist_name !== "Неизвестный Художник" ? (
                  <p className="text-small font-medium text-default-500">
                    {artist_name}
                  </p>
                ) : null}
                {availability === "Доступно" && price > 0 ? (
                  <div className="absolute left-3 top-3 z-20 bg-background/60 px-2 py-1 rounded-md backdrop-blur-md backdrop-saturate-150 dark:bg-default-100/50">
                    <span className="text-small font-medium text-default-900">
                      {new Intl.NumberFormat("ru-RU", {
                        style: "currency",
                        currency: "RUB",
                        minimumFractionDigits: 0,
                      }).format(price)}
                    </span>
                  </div>
                ) : null}
              </div>
              {description ? (
                <div>
                  <p
                    className={cn(
                      "text-small text-default-500 transition-all duration-300",
                      {
                        "line-clamp-3": !isExpanded,
                      }
                    )}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      maxHeight: !isExpanded ? "4.5em" : "none", // Approximate height for 3 lines of text
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: !isExpanded ? 3 : "unset",
                      textOverflow: "ellipsis",
                      userSelect: "none", // Prevent selection on text
                      WebkitUserSelect: "none",
                      WebkitTouchCallout: "none",
                    }}
                  >
                    {description}
                  </p>
                  {isExpanded && (
                    <div className="mt-2 space-y-1 text-small text-default-500">
                      {canvas !== "Неизвестно" && (
                        <p>
                          <strong>Холст:</strong> {canvas}
                        </p>
                      )}
                      {medium !== "Неизвестно" && (
                        <p>
                          <strong>Материал:</strong> {medium}
                        </p>
                      )}
                      {style && (
                        <p>
                          <strong>Стиль:</strong> {style}
                        </p>
                      )}
                      {year > 0 && (
                        <p>
                          <strong>Год:</strong> {year}
                        </p>
                      )}
                      {width > 0 && height > 0 && (
                        <p>
                          <strong>Размеры:</strong> {width} см x {height} см
                        </p>
                      )}
                    </div>
                  )}
                  <Button
                    className="mt-2 flex items-center gap-1"
                    size="sm"
                    variant="flat"
                    onPress={() => setIsExpanded(!isExpanded)}
                  >
                    <span>{isExpanded ? "Скрыть" : "Подробности"}</span>
                    <Icon
                      icon={isExpanded ? "mdi:chevron-up" : "mdi:chevron-down"}
                      width={16}
                    />
                  </Button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    );
  }
);

PaintingListItem.displayName = "PaintingListItem";

export default PaintingListItem;
