"use client";

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Image,
  Input,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import debounce from "lodash.debounce";

const youtubeUrl = "https://youtu.be/tHY3NkSewy8";
const telegramUrl = "https://t.me/+79119690469";
const whatsappUrl = "https://wa.me/79119690469";
const yandexMapsUrl = "https://yandex.ru/maps/org/paintx/49452764850";

export default function NavbarComponent({onSearch}) {
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

      // Debounce the search handler to avoid frequent API calls
  const handleSearch = useCallback(
    debounce((query) => {
      onSearch(query); // Pass the query to the parent component
      navigate("/"); // Redirect to the main view with the painting list
    }, 500),
    [navigate, onSearch]
  );

  // Update search term and call debounced search handler
  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    handleSearch(query);
  };

  const clearSearch = () => {
    setSearchTerm("");
    onSearch(""); // Reset search in parent
  };
  

  return (
    <div className="w-full">
      <Navbar className="fixed top-0 z-50 w-full bg-black shadow-lg border-b border-gray-800 px-4" height="80px">
        <NavbarContent className="flex items-center justify-between w-full gap-6">
          <NavbarBrand className="flex items-center px-6">
            <Link to="/">
              <Image src="/logo_mid_black.jpg" alt="PaintX Logo" className="h-8 object-contain" radius="none" />
            </Link>
          </NavbarBrand>

          <NavbarItem className="flex-grow relative">
            <div className="relative w-full">
              <Input
                aria-label="Поиск"
                className="w-full rounded-full px-12 py-2 text-lg"
                labelPlacement="outside"
                placeholder="Поиск картин..."
                value={searchTerm}
                onChange={handleInputChange}
                startContent={<Icon className="text-default-500" icon="fluent:search-20-regular" width={24} />}
              />

              {/* Conditionally render the clear (X) button when search term is not empty */}
              {searchTerm && (
                <button
                  onClick={clearSearch}
                  className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
                >
                  <Icon icon="mdi:close-circle" width={20} />
                </button>
              )}
            </div>
          </NavbarItem>

          {/* Contact Buttons */}
          <NavbarContent className="flex items-center gap-3 px-4">
            <button
              onClick={() => window.open(`${telegramUrl}`, "_blank")}
              className="bg-gray-700 text-white p-2 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-blue-500"
            >
              <Icon icon="simple-icons:telegram" width="24" height="24" />
            </button>
            <button
              onClick={() => window.open(`${youtubeUrl}`, "_blank")}
              className="bg-gray-700 text-white p-2 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-red-500"
            >
              <Icon icon="simple-icons:youtube" width="24" height="24" />
            </button>
            <button
              onClick={() => window.open(`${whatsappUrl}`, "_blank")}
              className="bg-gray-700 text-white p-2 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-green-500"
            >
              <Icon icon="simple-icons:whatsapp" width="24" height="24" />
            </button>
            <button
              onClick={() => window.open(`${yandexMapsUrl}`, "_blank")}
              className="bg-gray-700 text-white p-2 rounded-full shadow-md flex items-center justify-center transition-transform transform hover:scale-110 hover:bg-yellow-500"
            >
              <Icon icon="mdi:map-marker" width="24" height="24" />
            </button>
          </NavbarContent>
        </NavbarContent>
      </Navbar>
      <div className="pt-20 lg:pt-24"></div>
    </div>
  );
}
