import React, { useState } from "react";

const ArtworkRating = ({ data }) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => setShowDetails(!showDetails);

  return (
    <div className="bg-black text-gray-100 rounded-lg w-full shadow-lg">
      <div className="flex items-center mb-3">
        <h2 className="text-gray-200">Наша оценка картины</h2>
        <div
          className="ml-3 flex items-center bg-gray-700 text-gray-100 text-sm font-bold rounded-full px-3 py-1 cursor-pointer"
          onClick={toggleDetails}
        >
          <span>{data.overall}</span>
          <span className="ml-2 transform transition-transform duration-300">
            {showDetails ? (
              <svg
                className="w-4 h-4 transform rotate-180"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            ) : (
              <svg
                className="w-4 h-4"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            )}
          </span>
        </div>
      </div>

      {showDetails && (
        <div className="space-y-3 mt-3">
          {/* Rarity */}
          <div className="mb-4">
            <RatingItem label="Редкость" value={data.ratings[0].rarity} description={data.ratings[0].description} />
          </div>

          {/* Complexity */}
          <div className="mb-4">
            <RatingItem label="Сложность" value={data.ratings[1].complexity} description={data.ratings[1].description} />
          </div>

          {/* Quality */}
          <div className="mb-4">
            <RatingItem label="Качество" value={data.ratings[2].quality} description={data.ratings[2].description} />
          </div>

          {/* Appreciation */}
          <div className="mb-4">
            <RatingItem label="Востребованность" value={data.ratings[3].appreciation} description={data.ratings[3].description} />
          </div>

          {/* Overall */}
          <div className="border-t border-gray-700 mt-4 pt-2">
            <RatingItem label="Общая оценка" value={data.overall} description={data.closing} />
          </div>
        </div>
      )}
    </div>
  );
};

const RatingItem = ({ label, value, description }) => (
  <div className="flex items-center w-full text-sm">
    <span className="text-gray-300 w-1/4">{label}</span>
    <div className="flex items-center w-1/4">
      <span className="text-lg font-bold text-gray-200 mr-2 w-10">{value}</span>
      <div className="w-full h-3 bg-gray-700 rounded-full overflow-hidden">
        <div
          className="h-3 bg-blue-500 rounded-full"
          style={{ width: `${value * 10}%` }}
        ></div>
      </div>
    </div>
    <span className="text-gray-400 w-1/2 ml-4">{description}</span>
  </div>
);

export default ArtworkRating;
